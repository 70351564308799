import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  httpCreateEvent,
  httpGetBusinessBranches,
  httpGetEventList,
  httpRemoveBusinessBranch,
  httpUpdateBusinessBranches,
  httpUpdateOutletType,
} from 'app/api/commerce';
import { BUSINESS_BRANCHES } from 'app/state/constants';
import {
  BusinessBranchInterface,
  CommerceUpdateBusinessBranch,
} from 'app/utilities/types/userTypes';
import { CreateEventProps, OutletType } from 'app/utilities/types/shared';
import { useCallback, useEffect, useState } from 'react';
import { EventType } from 'app/components/ecommerce/events/EventList';
import { useGetProfile } from '../user';

export const useGetBusinessBranches = () => {
  const queryClient = useQueryClient();
  const profile = useGetProfile();

  const query = useMutation({
    mutationFn: () => httpGetBusinessBranches(profile.id),
    onSuccess: (data) => {
      queryClient.fetchQuery({
        queryKey: [BUSINESS_BRANCHES],
        queryFn: (_: any) => data,
        staleTime: Infinity,
      });
    },
  });
  return query;
};

export const useGetBranches = () => {
  const queryClient = useQueryClient();
  return (
    queryClient.getQueryData<BusinessBranchInterface[] | []>([
      BUSINESS_BRANCHES,
    ]) || []
  );
};

export const useAddBusinessBranch = () => {
  const queryClient = useQueryClient();
  const profile = useGetProfile();
  const query = useMutation({
    mutationFn: (payload: CommerceUpdateBusinessBranch) =>
      httpUpdateBusinessBranches(payload, profile.id),
    onSuccess: (data) => {
      queryClient.setQueryData([BUSINESS_BRANCHES], (oldData: any) => {
        const newArray = [...oldData, data];
        return newArray;
      });
    },
  });
  return query;
};

export const useRemoveBusinessBranch = () => {
  const queryClient = useQueryClient();
  const profile = useGetProfile();
  const query = useMutation({
    mutationFn: (payload: { slug: string }) =>
      httpRemoveBusinessBranch(payload.slug, profile.id),
    onSuccess: (data) => {
      queryClient.setQueryData([BUSINESS_BRANCHES], (oldData: any) => {
        const filteredArray = oldData.filter(
          (item: { slug: string }) => item.slug !== data.slug
        );
        return filteredArray;
      });
    },
  });
  return query;
};

export const useUpdateOutletType = () => {
  const queryClient = useQueryClient();
  const profile = useGetProfile();
  return useMutation({
    mutationFn: ({
      outletType,
      commerceId,
    }: {
      outletType: OutletType;
      commerceId: string;
    }) => httpUpdateOutletType(profile.id, outletType, commerceId),
    onSuccess: (data) => {
      queryClient.setQueryData(
        [`commerceProfile_${profile.id}`],
        (oldData: any) => ({
          ...oldData,
          outletType: data.outletType,
        })
      );
    },
  });
};

export const useCreateEvent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [eventData, setEventData] = useState<any>(null);

  const createEvent = async (eventDetails: CreateEventProps): Promise<any> => {
    setLoading(true);
    setError(null);
    try {
      const data = await httpCreateEvent(eventDetails);
      setEventData(data);
      return data;
    } catch (err: any) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    createEvent,
    loading,
    error,
    eventData,
  };
};

export const useFetchEventList = () => {
  const [eventListData, setEventListData] = useState<EventType[] | undefined>();
  const [loading, setLoading] = useState(false);

  const fetchEventList = useCallback(async (page = 1) => {
    setLoading(true);
    try {
      const result = await httpGetEventList({ page });
      const events = result.docs.map((event: any) => ({
        title: event.name,
        createdDate: event.createdAt,
        eventUUId: event.uuid,
      }));
      setEventListData(events);
    } catch (error: any) {
      // Handle error
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEventList(1);
  }, [fetchEventList]);

  return { eventListData, fetchEventList, loading };
};
