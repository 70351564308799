import { AccountType } from 'app/utilities/types/dispatcherTypes';
import { AgentsResult, Request } from 'app/utilities/types/shared';
import { User } from 'app/utilities/types/userTypes';

export interface AccountResponse {
  data: {
    tokens: {
      access: {
        token: string;
      };
      refresh: {
        token: string;
      };
    };
    user: User;
  };
}

export interface AccountPayloadResponse {
  data: AccountType;
}

export interface GeoDataResponse {
  data: {
    results: [
      {
        geometry: {
          location: {
            lat: number;
            lng: number;
          };
        };
        /* eslint-disable camelcase */
        address_components: Array<{
          long_name: string;
          short_name: string;
          types: Array<string>;
        }>;
      },
    ];
  };
}

export interface PayoutResponse {
  data: {
    message: string | undefined | null;
  };
}

export interface AcceptRequestResponse {
  data: {
    request: Request;
  };
}

export interface InitializeRequestResponse {
  data: {
    data: {
      authorization_url: string;
    };
    request: string;
    trackingId: string;
  };
}

export interface GetAgentsResponse {
  data: AgentsResult;
}

export interface AddBeneficiary {
  userId: string;
  beneficiary: object;
}

export const InitialInterfaceData = {
  docs: [],
  offset: 0,
  totalDocs: 0,
  totalPages: 0,
  hasNextPage: false,
  nextPage: 0,
  limit: 0,
  page: 0,
};

export interface EventDataInterface {
  id: string;
  uuid: string;
  name: string;
  description: string;
  location: string;
  eventDate: string;
  status: string;
  qrCode: string;
  bannerUrl: string;
  tickets: {
    active: boolean;
    charge: string;
    expiryDate: string;
    id: string;
    price: number;
    quantity: number;
    type: string;
  }[];
}

export interface Ticket {
  id: string; // The id of the ticket
  firstName: string; // Attendee's first name
  lastName: string; // Attendee's last name
  email: string; // Attendee's email
}

export interface EventContactInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface PurchaseTicketPayload {
  eventId: string;
  tickets: Ticket[];
  contactInfo: EventContactInfo;
  paymentRef: string;
  emailAll: boolean;
}

export interface TicketSalesDataInterface {
  totalAmountOfTicketSold: number;
  totalQuantityofTicketSold: number;
  count: number;
}

export type ListSoldTicketsParams = {
  eventId: string;
  page?: number;
  limit?: number;
};

export interface SoldTicketsResponse {
  _id: {
    email: string; // Attendee's email
    type: string; // The type of the ticket (e.g., VIP, Regular)
  };
  quantity: number; // Number of tickets sold
  id: string;
  email: string; // Attendee's email
  type: string; // The type of the ticket
  firstname: string; // Attendee's first name
  lastname: string; // Attendee's last name
  totalAmount: number;
  createdAt: string;
}

export interface ScanTicketResponse {
  ok: boolean;
}
