import { useQueryClient } from '@tanstack/react-query';
import { httpUploadFile } from 'app/api/utils';
import { useGetProfile } from 'app/hooks/user';
import { FileStructureResponse } from 'app/utilities/types/shared';
import CheckCircle from 'assets/images/CheckCircle.svg';
import CloudUpload from 'assets/images/CloudUpload.svg';
import ErrorOutline from 'assets/images/ErrorOutline.svg';
import spinner from 'assets/images/spinner-black.svg';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

interface Props {
  backgroundColor?: string;
  className?: string;
  size?: 'sm' | 'lg' | 'xm';
  placeholder?: string;
  onChange?: boolean;
  accept?: string;
  value?: any;
  name?: string;
  isValid?: boolean;
  disabled?: boolean;
  category?: 'document' | 'image';
  tag: string; // acts as a unique identifier to the inputs
  onUploaded: (payload: FileStructureResponse, tag: string) => void;
  autoUpload?: boolean;
  onTempUpload?: (file: { name: string; type: string }) => void;
  defaultFile?: { name: string; type: string };
}

export const ElrUpload: React.FC<Props> = ({
  className,
  size = 'lg',
  placeholder,
  onChange,
  accept = '.jpg',
  onUploaded,
  disabled,
  tag,
  isValid = false,
  category,
  name,
  ...props
}) => {
  const type = 'file';
  const [upload, setUpload] = useState('');
  const [fileName, setInputFileName] = useState<any>(
    props.value?.originalFilename
  );
  const [isUploading, setUploading] = useState<boolean>(false);
  const [isSuccess, setSuccessUploading] = useState<boolean>(
    props.value ? true : isValid
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();
  const { id: userId } = useGetProfile();

  const allowedFileExt = (file: string) => {
    if (
      !file.match(
        /\.(jpg|JPG|JPEG|PNG|jpeg|png|pdf|docx|doc|txt|XLS|PDF|DOCX|DOC|TXT)$/
      )
    ) {
      return false;
    }
    return true;
  };

  const makeUploadFileRequest = async (file: {
    name: string;
    type: string;
  }) => {
    const isImage = file.type.includes('image') ? 'image' : null;
    const isDocument = file.type.includes('application') ? 'document' : null;
    const fileType = category || isImage || isDocument || 'image';
    if (!allowedFileExt(file.name)) return;

    try {
      setUploading(true);
      const data = await queryClient.fetchQuery({
        queryKey: [file.name],
        queryFn: () => httpUploadFile(userId, file, fileType),
        gcTime: 30000,
      });

      const response = {
        key: data.key,
        originalFilename: file.name,
        type: file.type,
        url: data.url,
      };
      setUploading(false);
      setSuccessUploading(true);

      onUploaded(response, tag);
    } catch (error: any) {
      setUploading(false);
    }
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files || [];
    const fileValue = event.target.value;
    const files = Array.from(selectedFile);
    setUpload(fileValue);
    if (!files.length) return;
    const selectedFileName = files[0]?.name;
    setInputFileName(selectedFileName);
    makeUploadFileRequest(files[0]);
  };

  const handleClick = () => {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  return (
    <div
      onClick={handleClick}
      className="bg-elr-gray flex-col justify-center items-center flex overflow-hidden p-2 gap-x-3.5 w-72 mb-3 h-48 border border-elr-purple border-dashed cursor-pointer"
    >
      <div className="hidden h-8 pt-2 pl-1">
        {isSuccess ? (
          <span>
            <img src={CheckCircle} alt="check-circle" />
          </span>
        ) : (
          <span>
            <img src={ErrorOutline} alt="error-outline" />
          </span>
        )}
      </div>
      <div className="hidden w-full border-none bg-elr-gray h-11">
        <input
          {...props}
          onChange={onChangeInput}
          type={type}
          disabled={disabled}
          accept={accept}
          value={upload}
          className={`bg-elr-gray border-none h-11 w-full focus:outline-none ${
            type === 'file' && 'hidden'
          } `}
          ref={inputRef}
        />
      </div>
      {!disabled && (
        <div className="w-10 h-10 pt-3 cursor-pointer">
          {isUploading ? (
            <span className="mt-2 -mr-2">
              <img src={spinner} className="animate-spin h-3.5" alt="spinner" />
            </span>
          ) : (
            <div>
              <img src={CloudUpload} alt="cloud-upload" />
            </div>
          )}
        </div>
      )}

      <h3 className="text-lg">{fileName || name}</h3>
      <p className="pt-1 text-elr-gray-400">{placeholder}</p>
    </div>
  );
};

export const ElrUploadV2: React.FC<Props> = ({
  className,
  size = 'lg',
  placeholder,
  onChange,
  accept = '.jpg',
  onUploaded,
  disabled,
  tag,
  isValid = false,
  category,
  name,
  autoUpload = true,
  onTempUpload,
  defaultFile,
  ...props
}) => {
  const type = 'file';
  const [upload, setUpload] = useState('');
  const [fileName, setInputFileName] = useState<any>(
    props.value?.originalFilename
  );
  const [isUploading, setUploading] = useState<boolean>(false);
  const [isSuccess, setSuccessUploading] = useState<boolean>(
    props.value ? true : isValid
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();
  const { id: userId } = useGetProfile();

  const allowedFileExt = (file: string) => {
    if (
      !file.match(
        /\.(jpg|JPG|JPEG|PNG|jpeg|png|pdf|docx|doc|txt|XLS|PDF|DOCX|DOC|TXT)$/
      )
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (defaultFile) {
      const selectedFileName = defaultFile?.name;
      setInputFileName(selectedFileName);
      makeUploadFileRequest(defaultFile);
    }
  }, []);

  const makeUploadFileRequest = async (file: {
    name: string;
    type: string;
  }) => {
    const isImage = file.type.includes('image') ? 'image' : null;
    const isDocument = file.type.includes('application') ? 'document' : null;
    const fileType = category || isImage || isDocument || 'image';
    if (!allowedFileExt(file.name)) return;

    if (autoUpload) {
      try {
        setUploading(true);
        const data = await queryClient.fetchQuery({
          queryKey: [file.name],
          queryFn: () => httpUploadFile(userId, file, fileType),
          gcTime: 30000,
        });

        const response = {
          key: data.key,
          originalFilename: file.name,
          type: file.type,
          url: `${new URL(data.url).origin}${new URL(data.url).pathname}`,
        };
        setUploading(false);
        setSuccessUploading(true);

        onUploaded(response, tag);
      } catch (error: any) {
        setUploading(false);
      }
    } else {
      onTempUpload?.(file);
    }
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files || [];
    const fileValue = event.target.value;
    const files = Array.from(selectedFile);
    setUpload(fileValue);
    if (!files.length) return;
    // check if the file size is greater than 5MB
    const fileSizeInMB = files[0].size / (1024 * 1024);
    if (fileSizeInMB > 5) {
      toast.error('File size exceeds 5MB');
      return;
    }

    // Check if the image height is exactly 540px and width is exactly 960px
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (img.width != 960 || img.height != 540) {
          toast.error(
            'Image dimensions must be exactly 960px of width and 540px of height'
          );
          return;
        }

        const selectedFileName = file.name;
        setInputFileName(selectedFileName);
        makeUploadFileRequest(file);
      };
      img.src = e?.target?.result as string;
    };
    reader.readAsDataURL(file);
  };

  const handleClick = () => {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  return (
    <div
      onClick={handleClick}
      className="flex flex-col items-center justify-center overflow-hidden border border-dashed cursor-pointer border-elr-gray-neutral-300"
    >
      <div className="hidden h-8 pt-2 pl-1">
        {isSuccess ? (
          <span>
            <img src={CheckCircle} alt="check-circle" />
          </span>
        ) : (
          <span>
            <img src={ErrorOutline} alt="error-outline" />
          </span>
        )}
      </div>
      <div className="flex w-full h-24">
        <input
          {...props}
          onChange={onChangeInput}
          type={type}
          disabled={disabled}
          accept={accept}
          value={upload}
          className={`bg-elr-gray border-none h-11 w-full focus:outline-none ${
            type === 'file' && 'hidden'
          } `}
          ref={inputRef}
        />
        {!disabled && (
          <div className="relative w-full cursor-pointer">
            {isUploading ? (
              <span className="mt-2 -mr-2">
                <img
                  src={spinner}
                  className="animate-spin h-3.5"
                  alt="spinner"
                />
              </span>
            ) : (
              <div className="mt-4">
                <div className="flex justify-center gap gap-x-2 text-elr-purple">
                  <img src={CloudUpload} alt="cloud-upload" className="" />
                  <p>Upload Image</p>
                </div>
                <div className="text-xs text-center text-elr-disabled-gray">
                  <p>Files Supported: JPEG, PNG</p>
                  <p>Recommended Dimension: Width - 960px, Height - 540px</p>
                  <p>Maximum Size: 5MB</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <h3 className="text-lg">{fileName || name}</h3>
      <p className="pt-1 text-elr-gray-400">{placeholder}</p>
    </div>
  );
};
