import checkCircle from 'assets/images/CheckCircle.svg';
import checkCircleWhite from 'assets/images/CircleCheckWhite.svg';
import Dropdown from 'assets/images/dropdown.svg';
import BlackSpinner from 'assets/images/spinner-black.svg';
import WhiteSpinner from 'assets/images/spinner-white.svg';
import React from 'react';
import { rotateIcon } from '../ElrButtonDropdown/ClassUtils';
import * as styles from './ClassUtils';

interface Props {
  text: string;
  submit?: boolean;
  className?: string;
  size?: 'sm' | 'lg' | 'xm';
  loading?: boolean;
  spinnerColor?: 'White' | 'Black';
  imageSrc?: string | React.ReactNode;
  imageClass?: string;
  imageAlt?: string;
  icon?: React.ReactNode;
  imagePosition?: 'Right' | 'Left';
}

const selectSpinner = (colorOption?: string) => {
  const selectColorOption =
    colorOption === 'Black' ? BlackSpinner : WhiteSpinner;
  return (
    <img
      src={selectColorOption}
      className="animate-spin h-3.5 m-auto"
      alt="spinner"
    />
  );
};

export const ElrButton: React.FC<
  Props & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  text,
  submit,
  className,
  size = 'lg',
  loading = false,
  spinnerColor = 'Black',
  imageSrc,
  imageAlt,
  imageClass,
  imagePosition = 'Left',
  ...props
}) => {
  const sizes = {
    lg: 'min-w-10 min-h-3',
    sm: 'min-w-8 min-h-2',
    xm: 'min-w-8 min-h-full',
  };

  const value = loading ? selectSpinner(spinnerColor) : text;

  return (
    <button
      {...props}
      type={submit ? 'submit' : 'button'}
      className={`${className} ${sizes[size]} ${
        imageSrc ? 'flex flex-row justify-center items-center gap-2' : ''
      } disabled:opacity-50 rounded-3xl hover:opacity-70 focus:outline-none disabled:cursor-auto`}
    >
      {imageSrc && imagePosition === 'Left' && imageSrc}
      {value}
      {imageSrc && imagePosition === 'Right' && imageSrc}
    </button>
  );
};

export const ElrButtonSwitch: React.FC<
  Props &
    React.ButtonHTMLAttributes<HTMLButtonElement> & { isSelected: boolean }
> = ({
  text,
  submit,
  className = '',
  size = 'lg',
  loading = false,
  spinnerColor = 'Black',
  isSelected = false,
  ...props
}) => {
  const sizes = {
    lg: 'min-w-10 min-h-3',
    sm: 'min-w-8 min-h-2',
    xm: 'min-w-8 min-h-full',
  };

  const value = loading ? selectSpinner(spinnerColor) : text;

  return (
    <button
      {...props}
      type={submit ? 'submit' : 'button'}
      className={`${className} ${sizes[size]} ${styles.ElrButtonSwitchToggle(
        isSelected
      )} text-elr-black border border-elr-black bg-elr-black min-w-0 min-h-0 py-1 px-3 disabled:opacity-50  hover:opacity-70 focus:outline-none disabled:cursor-auto`}
    >
      {value}
    </button>
  );
};

export const ElrPillButton: React.FC<
  Props &
    React.ButtonHTMLAttributes<HTMLButtonElement> & {
      postFix?: boolean;
      handlePostFix?: () => void;
    }
> = ({
  text,
  submit,
  className,
  size = 'lg',
  loading = false,
  spinnerColor = 'Black',
  postFix,
  handlePostFix,
  ...props
}) => {
  const sizes = {
    lg: 'min-w-16 min-h-1',
    sm: 'min-w-16 min-h-1',
    xm: 'min-w-16 min-h-full',
  };

  const value = loading ? selectSpinner(spinnerColor) : text;

  return (
    <button
      {...props}
      type={submit ? 'submit' : 'button'}
      className={`${className} ${sizes[size]} disabled:opacity-50 rounded-3xl hover:opacity-70 focus:outline-none disabled:cursor-auto whitespace-nowrap`}
    >
      {value}
      {postFix && (
        <span className="px-2" onClick={handlePostFix}>
          X
        </span>
      )}
    </button>
  );
};

interface ToggleProps {
  toggler: boolean;
  text: string;
  className?: string;
}

export const ElrToggleButton: React.FC<
  ToggleProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ toggler, text, className, ...props }) => (
  <ElrButton
    {...props}
    text={text}
    className={`${className} !rounded-lg py-1 px-2 ${styles.batchItemButtonStyle(
      !toggler
    )}`}
    size="xm"
    disabled={toggler}
    imageSrc={toggler ? checkCircle : checkCircleWhite}
    imageAlt="check circle"
    imageClass="w-13 h-auto mr-1.5"
  />
);

interface DropDownButtonProps extends Props {
  arrowIconSize?: string;
  isToggled: boolean;
}

export const ElrDropdownButton: React.FC<
  DropDownButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  text,
  className,
  size = 'lg',
  isToggled,
  arrowIconSize = 'w-4 h-4',
  ...props
}) => {
  const sizes = {
    lg: 'min-w-10 min-h-3',
    sm: 'min-w-8 min-h-2',
    xm: 'min-w-8 min-h-full',
  };

  return (
    <button
      {...props}
      type="button"
      className={`${className} ${sizes[size]} w-full relative flex justify-center items-center disabled:opacity-50 rounded-3xl hover:opacity-70 focus:outline-none disabled:cursor-auto`}
    >
      {text}
      <img
        src={Dropdown}
        className={`${arrowIconSize} ${rotateIcon(
          isToggled
        )} float-right absolute right-0 mr-4`}
        alt="dropdown button"
      />
    </button>
  );
};

export const ElrButtonPreIcon: React.FC<
  Props & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  text,
  submit,
  className,
  size = 'lg',
  loading = false,
  spinnerColor = 'Black',
  imageSrc,
  imageAlt,
  imageClass,
  icon,
  ...props
}) => {
  const sizes = {
    lg: 'min-w-10 min-h-3',
    sm: 'min-w-8 min-h-2',
    xm: 'min-w-8 min-h-full',
  };

  const value = loading ? selectSpinner(spinnerColor) : text;

  return (
    <button
      {...props}
      type={submit ? 'submit' : 'button'}
      className={`${className} ${sizes[size]} disabled:opacity-50 rounded-3xl hover:opacity-70 focus:outline-none disabled:cursor-auto`}
    >
      <div className="flex items-center gap-2">
        {icon} <p>{value}</p>
      </div>
    </button>
  );
};
