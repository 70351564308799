import { EventDataInterface } from 'app/api/types';
import { useEffect, useState } from 'react';
import { usePaystackPayment } from 'react-paystack';
import * as Yup from 'yup';

import { PAYSTACK_PUBLIC_KEY } from 'app/constants';
import {
  useFetchEvent,
  useGetEventTicketLeft,
  useManageTicket,
  usePurchaseTicket,
} from 'app/hooks/event';
import { calculatePaymentFeeV2 } from 'app/utilities/helpers';
import { publicRoutes } from 'app/utilities/routes';
import { EventChargesType } from 'app/utilities/types/shared';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router';
import {
  ElrButton,
  ElrCheckboxToggle,
  ElrInput,
  ElrPhoneInput,
} from 'ui-components';
import { Footer, Header } from './utils';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
});

export const EventContactInformation: React.FC = () => {
  const navigation = useNavigate();
  const [shareTicket, setShareTicket] = useState(false);
  const [event, setEvent] = useState<EventDataInterface | null>(null);
  const params = useParams();
  const [telephone, setTelephone] = useState<{
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }>({ isValid: false, mobile: '', formattedNumber: '' });
  const [loading, setLoading] = useState(false);
  const { eventUUId } = params;
  const { getRequestedTickets } = useManageTicket(eventUUId as string);
  const { purchaseTicket } = usePurchaseTicket();
  const [attendees, setAttendees] = useState<
    {
      firstName: string;
      lastName: string;
      email: string;
      confirmEmail: string;
    }[]
  >([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { getEvent } = useFetchEvent(eventUUId as string, setEvent);
  const { getEventTicketLeft } = useGetEventTicketLeft();

  const requestedTickets =
    getRequestedTickets()?.tickets.filter(
      (ticket) => ticket.quantityRequested > 0
    ) || [];

  useEffect(() => {
    getEvent();
  }, []);

  const eventId = getRequestedTickets()?.eventId;

  const validateForm = (values: any) =>
    !values.firstName ||
    !values.lastName ||
    !values.email ||
    !telephone.isValid || // Check for valid phone number
    (shareTicket &&
      attendees.some(
        (attendee) =>
          !attendee?.firstName ||
          !attendee?.lastName ||
          !attendee?.email ||
          attendee?.email !== attendee?.confirmEmail
      ));

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const isDisabled = validateForm(values); // Use the modular function

      if (isDisabled) {
        setErrorMessage(
          'Please fill in all required fields, ensure emails match, and provide a valid phone number.'
        );
        return;
      }

      // Reset error message if all validations pass
      setErrorMessage('');

      goToPaystack();
    },
  });

  const isDisabled = validateForm(formik.values); // Use the modular function

  // Update the onChange function to save values in the attendees array
  const handleAttendeeChange = (
    index: number,
    payload: {
      firstName: string;
      lastName: string;
      email: string;
      confirmEmail: string;
    }
  ) => {
    const newAttendees = [...attendees];
    newAttendees[index] = payload;
    setAttendees(newAttendees);
  };

  const getTicketsLeftBeforePayment = async () => {
    await getEventTicketLeft({
      eventId: eventId as string,
      tickets: requestedTickets.map((ticket) => ({
        id: ticket.id,
        quantity: ticket.quantityRequested,
      })),
    });
  };

  const goToPaystack = () => {
    getTicketsLeftBeforePayment().then(() => {
      const MINIMUM_PAYSTACK = 50;
      totalAmountInKobo >= MINIMUM_PAYSTACK &&
        initializePayment({
          onSuccess: (data) => onPurchaseEventTicket(data),
          onClose: () => {},
        });
    });
  };

  const onPurchaseEventTicket = async (data: any) => {
    try {
      setLoading(true);
      const createTicketPayload = (
        attendee: {
          firstName: string;
          lastName: string;
          email: string;
        },
        ticket: EventDataInterface['tickets'][number] & {
          quantityRequested: number;
        }
      ) => ({
        firstName: attendee.firstName,
        id: ticket.id,
        lastName: attendee.lastName,
        email: attendee.email,
      });

      const tickets = shareTicket
        ? [
            ...requestedTickets.map((ticket) => ({
              id: ticket.id,
              firstName: formik.values.firstName, // Add default values for firstName and lastName
              lastName: formik.values.lastName,
              email: formik.values.email,
            })),
            ...attendees.map(
              (attendee) => createTicketPayload(attendee, requestedTickets[0]) // Adjust as necessary
            ),
          ]
        : requestedTickets.flatMap((ticket) =>
            Array.from({ length: ticket.quantityRequested }, () =>
              createTicketPayload(
                {
                  firstName: formik.values.firstName,
                  lastName: formik.values.lastName,
                  email: formik.values.email,
                },
                ticket
              )
            )
          );

      const payload = {
        eventId: eventId as string,
        tickets,
        contactInfo: {
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          email: formik.values.email,
          phone: telephone.formattedNumber,
        },
        paymentRef: data.reference,
        emailAll: shareTicket,
      };
      await purchaseTicket(payload);
      navigation(`${publicRoutes.event}/${eventUUId}/purchase-success`);
    } catch (error) {
      /**
       * TODO: Handle error
       */
      console.log('purchase event ticket', error);
    } finally {
      setLoading(false);
    }
  };

  const onPhoneInputChange = (payload: {
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }) => {
    setTelephone((prev) => ({ ...prev, ...payload }));
  };

  const businessCoveredTickets = requestedTickets
    .filter((ticket) => ticket.charge === EventChargesType.Business)
    .map((ticket) => ticket.type);

  const businessCoveredText =
    businessCoveredTickets.length === 1 ? 'is' : 'are';

  const calculateTotalWithCharges = () => {
    let totalTicketPrice = 0;
    let totalFlatFees = 0;
    const FLAT_FEE = 150;

    requestedTickets.forEach(({ price, quantityRequested, charge }) => {
      const ticketTypePrice = price * quantityRequested;
      totalTicketPrice += ticketTypePrice;
      if (charge === EventChargesType.Customer) {
        totalFlatFees += FLAT_FEE * quantityRequested;
      }
    });

    const totalBeforePaymentFee = totalTicketPrice + totalFlatFees;
    const { finalFee, paymentFee } = calculatePaymentFeeV2(
      totalBeforePaymentFee
    );

    return {
      totalAmountInKobo: Math.round(finalFee * 100),
      paymentFee,
      flatFees: totalFlatFees,
      totalBeforePaymentFee,
    };
  };

  const { totalAmountInKobo, paymentFee, flatFees, totalBeforePaymentFee } =
    calculateTotalWithCharges();

  const totalRequestedTicketsPrice = totalBeforePaymentFee + paymentFee;

  const config = {
    email: formik.values.email,
    amount: totalRequestedTicketsPrice * 100,
    publicKey: PAYSTACK_PUBLIC_KEY,
    channels: ['card', 'ussd', 'bank_transfer'],
    metadata: {
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      email: formik.values.email,
    },
  };
  const initializePayment = usePaystackPayment(config as any);

  if (!event) return <div />;

  return (
    <>
      <Header eventId={event.uuid} />
      <main className="min-h-full px-4 pt-10 pb-40 bg-white md:pt-14 xl:px-48 lg:px-32 md:px-20">
        <h3 className="text-lg font-medium">Contact Information</h3>
        <p className="mt-1 text-opacity-50 text-elr-black">
          The ticket details and QR code will be sent here
        </p>
        <form onSubmit={formik.handleSubmit}>
          <section className="flex flex-col px-4 py-5 mt-4 rounded md:px-8 md:py-8 bg-elr-gray-100 gap-y-5 md:gap-y-8">
            <div className="flex flex-col items-center justify-between md:flex-row gap-y-5 md:gap-x-8">
              <ElrInput
                className="w-full"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                placeholder="First Name"
              />
              <ElrInput
                className="w-full"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                placeholder="Last Name"
              />
            </div>
            <div className="flex flex-col items-center justify-between md:flex-row gap-y-5 md:gap-x-8">
              <ElrInput
                value={formik.values.email}
                type="email"
                name="email"
                onChange={formik.handleChange}
                placeholder="Email Address"
                className="w-full"
              />
              <ElrPhoneInput
                containerClass="w-full"
                value={telephone.mobile}
                onChange={onPhoneInputChange}
                placeholder="Phone Number"
              />
            </div>
          </section>
          <section className="flex items-center mt-8 gap-x-2">
            <ElrCheckboxToggle
              checked={shareTicket}
              onChange={() => setShareTicket(!shareTicket)}
            />
            <div>
              <h4 className="text-lg font-medium">
                Share tickets to different email addresses
              </h4>
              <p className="mt-0.5 text-opacity-50 text-elr-black">
                The ticket details and QR code will be sent to the email
                addresses you provide
              </p>
            </div>
          </section>

          {shareTicket && (
            <section className="flex flex-col mt-8 gap-y-7">
              {requestedTickets.map((ticket, index) => (
                <div key={ticket.id}>
                  <h4 className="text-lg font-medium capitalize">
                    Ticket {index + 1} - {ticket.type}
                  </h4>
                  <TicketAttendee
                    index={index}
                    onChange={handleAttendeeChange}
                    attendee={
                      attendees[index] || {
                        firstName: '',
                        lastName: '',
                        email: '',
                        confirmEmail: '',
                      }
                    }
                  />
                </div>
              ))}
            </section>
          )}

          <h3 className="md:mt-16 mt-10 text-lg font-medium">Ticket Summary</h3>
          <div className="pt-2 pb-4 ml-8">
            <ul className="list-disc text-opacity-50 text-elr-black flex flex-col gap-1">
              <li>A flat fee of NGN 150 applies to each ticket.</li>
              {businessCoveredTickets.length > 0 && (
                <li>
                  The NGN 150 flat fee for {businessCoveredTickets.join(', ')}{' '}
                  {businessCoveredText} covered by the event organizers.
                </li>
              )}
              <li>
                There is an additional payment gateway charge calculated based
                on the total amount.
              </li>
            </ul>
          </div>

          <section className="flex flex-col px-4 py-5 mt-4 rounded md:px-10 md:py-10 bg-elr-gray-100 gap-y-7">
            <div className="flex flex-col px-4 py-5 bg-white rounded md:px-10 md:py-10 gap-y-8">
              {requestedTickets.map((ticket) => (
                <Ticket key={ticket.id} ticket={ticket} />
              ))}

              <div className="flex items-center justify-between gap-x-4">
                <p className="w-1/3 text-base font-medium capitalize md:text-lg text-elr-black text-opacity-80">
                  Flat Fees
                </p>
                <p className="w-1/3 text-lg font-medium text-right md:text-xl text-elr-black text-opacity-80">
                  NGN&nbsp;{' '}
                  {flatFees.toLocaleString('en-NG', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>

              <div className="flex items-center justify-between gap-x-4">
                <p className="w-1/3 text-base font-medium capitalize md:text-lg text-elr-black text-opacity-80">
                  Payment Charge
                </p>
                <p className="w-1/3 text-lg font-medium text-right md:text-xl text-elr-black text-opacity-80">
                  NGN&nbsp;{' '}
                  {paymentFee.toLocaleString('en-NG', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>

              {/* To handle Business covers fees  */}
              {requestedTickets.some(
                (ticket) => ticket.charge === EventChargesType.Business
              ) && (
                <div className="flex items-center justify-between gap-x-4">
                  <p className="w-1/3 text-base font-medium capitalize md:text-lg text-elr-black text-opacity-80">
                    Business covers
                  </p>
                  <p className="w-1/3 text-lg font-medium text-right md:text-xl text-elr-black text-opacity-80">
                    NGN&nbsp;
                    {Intl.NumberFormat('en-NG', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(flatFees * businessCoveredTickets.length)}
                  </p>
                </div>
              )}

              <div className="flex items-center justify-between pt-6 border-t border-t-elr-gray-200 gap-x-4">
                <p className="text-lg font-bold md:text-xl text-elr-black text-opacity-80">
                  Total
                </p>
                <p className="text-lg font-bold md:text-xl text-elr-black text-opacity-80">
                  NGN&nbsp;{' '}
                  {Intl.NumberFormat('en-NG', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(totalRequestedTicketsPrice)}
                </p>
              </div>
            </div>
          </section>
          {errorMessage && (
            <div className="text-red-500 mt-2">{errorMessage}</div>
          )}
          <div className="flex items-center justify-end mt-10">
            <ElrButton
              type="button"
              submit
              loading={loading}
              spinnerColor="White"
              className="text-white w-full md:w-auto !rounded bg-elr-black px-10"
              text="Make Payment"
              disabled={isDisabled}
            />
          </div>
        </form>
      </main>
      <Footer />
    </>
  );
};

interface TicketAttendeeProps {
  index: number;
  onChange: (index: number, attendee: any) => void;
  attendee: {
    firstName: string;
    lastName: string;
    email: string;
    confirmEmail: string;
  };
}

const TicketAttendee: React.FC<TicketAttendeeProps> = ({
  index,
  onChange,
  attendee,
}) => (
  <div className="flex flex-col px-4 py-5 mt-3 rounded md:px-8 md:py-8 bg-elr-gray-100 gap-y-5 md:gap-y-8">
    <div className="flex flex-col items-center justify-between md:flex-row gap-y-5 md:gap-x-8">
      <ElrInput
        className="w-full"
        required
        value={attendee.firstName}
        onChange={(e) =>
          onChange(index, { ...attendee, firstName: e.target.value })
        }
        placeholder="Attendee First Name"
      />
      <ElrInput
        className="w-full"
        required
        value={attendee.lastName}
        onChange={(e) =>
          onChange(index, { ...attendee, lastName: e.target.value })
        }
        placeholder="Attendee Last Name"
      />
    </div>
    <div className="flex flex-col items-center justify-between md:flex-row gap-y-5 md:gap-x-8">
      <ElrInput
        value={attendee.email}
        type="email"
        required
        onChange={(e) =>
          onChange(index, { ...attendee, email: e.target.value })
        }
        placeholder="Attendee Email Address"
        className="w-full"
      />
      <ElrInput
        value={attendee.confirmEmail}
        type="email"
        required
        onChange={(e) =>
          onChange(index, { ...attendee, confirmEmail: e.target.value })
        }
        placeholder="Confirm Attendee Email Address"
        className="w-full"
      />
    </div>
  </div>
);

const Ticket: React.FC<{
  ticket: EventDataInterface['tickets'][number] & { quantityRequested: number };
}> = ({ ticket }) => (
  <div className="flex items-center justify-between gap-x-4">
    <p className="w-1/3 text-base font-medium capitalize md:text-lg text-elr-black text-opacity-80">
      {ticket.type}
    </p>
    <p className="w-1/3 text-base font-bold text-center md:text-lg">
      {ticket.quantityRequested}
    </p>
    <p className="w-1/3 text-lg font-medium text-right md:text-xl text-elr-black text-opacity-80">
      NGN&nbsp;
      {Intl.NumberFormat('en-NG', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(ticket.price * ticket.quantityRequested)}
    </p>
  </div>
);
