import {
  EventDataInterface,
  SoldTicketsResponse,
  TicketSalesDataInterface,
} from 'app/api/types';
import { DateTime } from 'luxon';
import {
  CopyDoubleIcon,
  DownloadLineIcon,
  DrawerIcon,
  InformationIcon,
  MoneyIcon,
  PencilIcon,
  PlusIcon,
  QRcodeIcon,
} from 'app/components/SvgAssets';
import {
  useCloseEvent,
  useFetchEvent,
  useFetchTicketSales,
  useListSoldTickets,
  useToggleTicket,
} from 'app/hooks/event';
import { useGetProfile } from 'app/hooks/user';
import copyToClipboard from 'app/utilities/copyToClipboard';
import { currencyFormatter } from 'app/utilities/helpers';
import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes, publicRoutes } from 'app/utilities/routes';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ElrModal, ElrSwitch } from 'ui-components';
import {
  ElrButton,
  ElrButtonPreIcon,
  ElrDropdownButton,
} from 'ui-components/components/ElrButton';
import SlideIn from 'app/components/SlideIn/SlideIn';
import { EventChargesType, Ticket } from 'app/utilities/types/shared';
import EventDropdown from './EventDropdown';
import ErrorIcon from '../../../../assets/images/error-icon.svg';
import MoreIcon from '../../../../assets/images/more-2-fill.svg';
import './index.css';

const EventDashboard = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [event, setEvent] = useState<EventDataInterface | null>(null);
  const [ticketSales, setTicketSales] = useState<TicketSalesDataInterface[]>(
    []
  );
  const [, setIsDropdownVisible] = useState(false);
  const [, setIsQrDropdownVisible] = useState(false);

  const [soldTickets, setSoldTickets] = useState<SoldTicketsResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { eventUUId } = params;
  const {
    accountInformation: { id: roleId },
  } = useGetProfile();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { getEvent } = useFetchEvent(eventUUId as string, setEvent);
  const { fetchTicketSales } = useFetchTicketSales();
  const { toggleTicket } = useToggleTicket();
  const { closeEvent } = useCloseEvent();
  const { listSoldTickets } = useListSoldTickets();
  const location = useLocation();
  const {
    eventName = event?.name,
    eventLocation = event?.location,
    eventDescription = event?.description,
    eventDate = event?.eventDate,
    eventTime = eventDate
      ? new Date(eventDate).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
      : '',
    createdDate = location.state?.createdDate || 'N/A',
    ticketTypes = event?.tickets,
  } = location.state || {};

  useEffect(() => {
    const handleClickOutside = (clickEvent: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(clickEvent.target as Node)
      ) {
        setIsDropdownVisible(false);
        setIsQrDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getEvent();
  }, []);

  useEffect(() => {
    if (event) {
      fetchTicketSales(event.id, (sales) => {
        if (sales !== null) {
          setTicketSales(sales);
        }
      });
      listSoldTickets(
        { eventId: event.id, page: 1, limit: 100 },
        (fetchedSoldTickets) => {
          if (fetchedSoldTickets !== null) {
            setSoldTickets(fetchedSoldTickets);
          }
        }
      );
    }
  }, [event?.id, fetchTicketSales, listSoldTickets]);

  if (!event) return <div />;
  const eventIsClosed = event.status === 'closed';

  const eventLink = `${window.location.origin}${publicRoutes.event}/${event.uuid}`;
  const calculateTotalSalesAmount = (
    businessticketSales: TicketSalesDataInterface[] = []
  ): number =>
    businessticketSales.reduce(
      (total, sale) => total + sale.totalAmountOfTicketSold,
      0
    );

  const calculateTotalTicketsSold = (
    businessticketSales: TicketSalesDataInterface[] = []
  ): number =>
    businessticketSales.reduce(
      (total, sale) => total + sale.totalQuantityofTicketSold,
      0
    );

  const handleCopy = () => {
    copyToClipboard(eventLink);
    toast.success('Event link copied to clipboard');
  };

  const formatExpiryDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleToggleTicket = async (
    ticketType: string,
    ticketStatus: boolean
  ) => {
    try {
      setIsLoading(true);
      await toggleTicket(event.id, ticketType, ticketStatus);
      getEvent();
    } catch (error) {
      // handle error
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseEvent = async () => {
    try {
      setIsLoading(true);
      await closeEvent(event.id, roleId);
      getEvent();
      toast.success('Event closed successfully');
    } catch (error) {
      toast.error('Error closing event');
    } finally {
      setIsLoading(false);
      setShowModal(false);
    }
  };

  const handleAddNewEvent = () => {
    navigate(`/${COMMERCE}/${privateRoutes.ecommerce}/${privateRoutes.events}`);
  };

  const qrOptions = [
    {
      value: 'Download Event QR Code',
      label: 'Download Event QR Code',
      icon: <DownloadLineIcon />,
    },
    {
      value: 'Scan QR Code Link',
      label: 'Scan QR Code Link',
      icon: <CopyDoubleIcon fill="#636366" />,
    },
  ];

  const moreOptions = [
    { value: 'Request Sponsorship', label: 'Request Sponsorship' },
    { value: 'Edit Event Info', label: 'Edit Event Info' },
  ];

  const handleSelectQrOption = (selectedValue: string) => {
    if (selectedValue === 'Download Event QR Code') {
      let qrCodeData = event.qrCode;
      if (!qrCodeData.startsWith('data:image/png;base64,')) {
        qrCodeData = `data:image/png;base64,${qrCodeData}`;
      }
      const base64Data = qrCodeData.split(',')[1];
      const byteString = atob(base64Data);
      const mimeString = qrCodeData.split(',')[0].split(':')[1].split(';')[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i += 1) {
        uint8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: mimeString });
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${event.name}-qrcode.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } else if (selectedValue === 'Scan QR Code Link') {
      window.open(publicRoutes.eventQrScan, '_blank');
    }
  };

  const handleMoreOptionSelect = (selectedValue: string) => {
    setIsDropdownVisible(false);
    if (selectedValue === 'Request Sponsorship') {
      // To handle Request Sponsorship
    } else if (selectedValue === 'Edit Event Info') {
      setIsModalOpen(true);
    }
  };

  // const handleEditClick = () => {
  //   navigate(
  //     `/${COMMERCE}/${privateRoutes.ecommerce}/${privateRoutes.events}`,
  //     {
  //       state: {
  //         eventName,
  //         eventUUId,
  //         eventLocation,
  //         eventDescription,
  //         eventDate,
  //         eventTime,
  //         ticketTypes,
  //         isEditing: true,
  //       },
  //     }
  //   );
  // };

  const handleEditClick = () => {};

  const eventDateObject = new Date(eventDate);
  const formattedDate = isNaN(eventDateObject.getTime())
    ? 'Invalid Date'
    : DateTime.fromJSDate(eventDateObject).toFormat('dd-MM-yyyy');

  const createdDateObject = new Date(createdDate);
  const formattedCreatedDate = isNaN(createdDateObject.getTime())
    ? 'Invalid Date'
    : DateTime.fromJSDate(createdDateObject).toFormat('dd-MM-yyyy');

  const capitalize = (s: any) => s && s[0].toUpperCase() + s.slice(1);

  const renderEditEventInfo = (
    <SlideIn
      close={() => setIsModalOpen(false)}
      shown={isModalOpen}
      leftMarginOffset="md:ml-[60%]"
    >
      <p className="text-center pb-6">Event Information</p>
      <div>
        <ElrButton
          text={'Edit Event'}
          className="flex items-center w-full gap-2 align-middle rounded-sm bg-elr-gray text-elr-black"
          onClick={handleEditClick}
          imageSrc={<PencilIcon />}
          imagePosition="Right"
        />
        <div className="w-full py-8 mx-auto mb-10 bg-elr-white-400">
          <div>
            <section className="flex-col mb-6 md:flex md:flex-row">
              <p className="w-1/2 pb-1 text-elr-black-300">Event Name</p>
              <p>{eventName}</p>
            </section>
            <section className="flex-col mb-6 md:flex md:flex-row">
              <p className="w-1/2 pb-1 text-elr-black-300">Event Description</p>
              <p className="md:w-1/2 w-9/10">{eventDescription}</p>
            </section>
            <section className="flex-col mb-6 md:flex md:flex-row">
              <p className="w-1/2 pb-1 text-elr-black-300">Event Location</p>
              <p className="md:w-1/2 w-9/10">{eventLocation}</p>
            </section>
            <section className="flex-col mb-6 md:flex md:flex-row">
              <p className="w-1/2 pb-1 text-elr-black-300">Event Date</p>
              <p className="w-1/2">{formattedDate}</p>
            </section>
            <section className="flex-col mb-6 md:flex md:flex-row">
              <p className="w-1/2 pb-1 text-elr-black-300">Event Time</p>
              <p className="w-1/2">{eventTime.toLocaleUpperCase()}</p>
            </section>

            {ticketTypes?.map((ticket: Ticket, index: number) => (
              <section
                key={ticket.id}
                className="flex-col mb-8 md:flex md:flex-row"
              >
                <p className="w-1/2 pb-1 text-elr-black-300">
                  Ticket {index + 1}
                </p>
                <div className="w-full md:w-1/2">
                  <p>{capitalize(ticket.type)}</p>
                  <p>NGN {ticket.price}</p>
                  <p>Quantity - {ticket.quantity}</p>
                  <p>
                    Deadline -{' '}
                    {ticket.expiryDate
                      ? new Date(ticket.expiryDate).toLocaleDateString()
                      : 'N/A'}
                  </p>
                  <p>
                    {ticket.whoPays === EventChargesType.Business
                      ? 'Business pays'
                      : 'Customer pays'}{' '}
                    payment charges
                  </p>
                </div>
              </section>
            ))}
            <section className="flex-col mb-6 md:flex md:flex-row">
              <p className="w-1/2 pb-1 text-elr-black-300">Created At</p>
              <p className="w-1/2">{formattedCreatedDate}</p>
            </section>
          </div>
        </div>
      </div>
    </SlideIn>
  );

  return (
    <div className="pt-8 px-40 pb-8">
      {showModal && (
        <EventTurnOffModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onClick={handleCloseEvent}
          loading={isLoading}
        />
      )}
      {isModalOpen && renderEditEventInfo}
      <section className="flex justify-between pb-8">
        <div>
          <h3>Events</h3>
          <p className="text-elr-black-300">
            View and follow up with your events here.
          </p>
        </div>
        <div className="flex gap-4 items-center">
          <ElrButtonPreIcon
            text={'Add New Event'}
            icon={<PlusIcon fill="#081120" />}
            onClick={handleAddNewEvent}
            className="bg-elr-white-400 text-elr-black rounded-sm py-3 px-5"
          />
          <EventDropdown
            buttonLabel="QR Code"
            buttonIcon={<QRcodeIcon width="30" />}
            options={qrOptions}
            onSelect={handleSelectQrOption}
            dropdownPosition="right"
            className="bg-elr-white-400 text-elr-black rounded-md px-3 py-3"
          />
          <EventDropdown
            buttonLabel={<img src={MoreIcon} alt="more-icon" />}
            options={moreOptions}
            onSelect={handleMoreOptionSelect}
            dropdownPosition="right"
            showDropdownIcon={false}
            className="bg-elr-black rounded-md px-3 py-2"
          />
        </div>
      </section>
      <section className="px-8 py-4 flex justify-between bg-elr-white-400 mb-5">
        <div className="flex items-center">
          <p>{event.name} &nbsp; - &nbsp;</p>
          <div className="bg-elr-gray py-2 px-3">
            <p className="text-elr-purple">{eventLink}</p>
          </div>
        </div>
        <div className="flex gap-x-4">
          {!eventIsClosed ? (
            <ElrButton
              text={'Turn off Event'}
              disabled={isLoading}
              loading={isLoading}
              onClick={() => setShowModal(true)}
              className="bg-elr-gray text-elr-error rounded-sm py-3 px-5"
            />
          ) : (
            <ElrButton
              text={'Event Closed'}
              disabled
              onClick={handleCloseEvent}
              className="bg-elr-gray text-success rounded-sm py-3 px-5"
            />
          )}
          <ElrButtonPreIcon
            text={'Copy Event Link'}
            icon={<CopyDoubleIcon />}
            onClick={handleCopy}
            className="bg-elr-black text-elr-white-400 rounded-sm py-3 px-5"
          />
        </div>
      </section>
      <section className="grid grid-cols-column-two gap-4 mb-5">
        <div className="bg-elr-white-400 py-5 px-6">
          <div>
            <div className="float-end">
              <ElrButton
                text={'Withdraw'}
                disabled={isLoading}
                loading={isLoading}
                className="bg-elr-black text-elr-white-400 rounded-sm py-3 px-5"
              />
            </div>
            <MoneyIcon />
            <div className="mb-5" />
            <p className="text-lg text-elr-black-300 leading-5 mb-3">
              Total Sales
            </p>
            <p className="text-3xl text-elr-black">
              {currencyFormatter(calculateTotalSalesAmount(ticketSales))}
            </p>
          </div>
        </div>
        <div className="bg-elr-white-400 py-5 px-6">
          <div>
            <DrawerIcon />
            <div className="mb-5" />
            <p className="text-lg text-elr-black-300 leading-5 mb-3">
              Total Tickets Sold
            </p>
            <p className="text-3xl text-elr-black">
              {calculateTotalTicketsSold(ticketSales)}
            </p>
          </div>
        </div>
      </section>
      <section className="bg-elr-white-400 py-5 px-8 mb-5">
        <h3>Event Tickets</h3>
        <div className="mt-4 flex gap-x-4">
          {event.tickets.map((ticket) => (
            <div key={ticket.id} className="bg-elr-gray py-4 px-5 flex">
              <div>
                <p>{ticket.type.toLocaleUpperCase()}</p>
                <p className="text-elr-black-300">N{ticket.price}</p>
                <p className="text-elr-black-300">
                  Quantity Sold - {ticket.quantity}
                </p>
                <p className="text-elr-black-300">
                  Sales Deadline - {formatExpiryDate(ticket.expiryDate)}
                </p>
              </div>
              <div className="self-center">
                <ElrSwitch
                  isToggled={ticket.active}
                  toggle={() => handleToggleTicket(ticket.type, !ticket.active)}
                  parentClassName="bg-elr-white-400"
                  circleClassName="bg-elr-black"
                  toggledCircleClassName="bg-elr-white-400"
                  toggledParentClassName="!bg-elr-black"
                />
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="bg-elr-white-400">
        <div className="py-4 px-8 grid grid-cols-column-two">
          <h3 className="self-center">Ticket Sales Breakdown - First(100)</h3>
          <div className="flex justify-between gap-2">
            <ElrDropdownButton
              text="All Tickets"
              isToggled={false}
              className="bg-elr-gray rounded-sm !w-1/2"
              size="lg"
            />
            <ElrButtonPreIcon
              text={'View Event Info'}
              icon={<InformationIcon />}
              className="bg-elr-gray text-elr-black w-1/2 rounded-sm py-3 px-5"
            />
          </div>
        </div>
        <div>
          <table className="table-auto w-full">
            <thead>
              <tr className="border border-x-0 border-elr-gray">
                <th className="py-5">Attendee</th>
                <th>Ticket Type</th>
                <th>Quantity</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {soldTickets.map((ticket) => (
                <tr
                  key={ticket.id}
                  className="text-center border border-x-0 border-elr-gray"
                >
                  <td className="py-5">
                    {ticket.firstname} {ticket.lastname}
                  </td>
                  <td>{ticket.type}</td>
                  <td>{ticket.quantity}</td>
                  <td>{ticket.totalAmount}</td>
                  <td>{new Date(ticket.createdAt).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default EventDashboard;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  loading: boolean;
}

export const EventTurnOffModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onClick,
  loading,
}) => (
  <ElrModal isOpen={isOpen} onClose={onClose}>
    <div className="flex flex-col items-center text-center py-10 px-6">
      <div className="flex items-center justify-center mb-8">
        <img src={ErrorIcon} alt="error-icon" className="h-20 w-20" />
      </div>
      <p className="text-2xl text-elr-black pb-4 w-10/12">
        You are about to deactivate this Event
      </p>
      <p className="text-base text-center text-elr-black-400 w-11/12">
        This event will be removed from the sales platform and the event tickets
        will no longer be sold.
      </p>
      <p className="text-base text-center text-elr-black-400 pt-4">
        Do you still want to proceed?
      </p>
      <div className="flex gap-2.5 mt-10 w-full">
        <ElrButton
          text="Yes, Deactivate It"
          className="py-2 px-4 w-full bg-elr-gray text-elr-black h-10 rounded-md"
          spinnerColor="Black"
          onClick={onClick}
          loading={loading}
        />
        <ElrButton
          text="No, Go Back"
          className="bg-elr-black text-white w-full py-2 px-4 h-10 rounded-md"
          onClick={onClose}
        />
      </div>
    </div>
  </ElrModal>
);
