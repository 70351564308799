import { TopNav } from 'app/components/menu/Navbar';
import appStore from 'assets/images/app-store-btn.svg';
import appMockup from 'assets/images/appIconMock.png';
import bgGradient from 'assets/images/bgGradient.svg';
import googlePlay from 'assets/images/google-play-btn.svg';
import linePattern from 'assets/images/linePattern.png';
import React from 'react';

export const Header: React.FC = () => (
  <div className="relative w-full md:pb-6">
    <div className="relative z-10">
      <TopNav />
    </div>
    <img src={bgGradient} alt="bg-gradient" className="absolute top-0" />
    <img src={linePattern} alt="line-pattern" className="absolute" />
    <div className="relative flex flex-col items-center justify-center gap-8 pt-20 mb-10 text-center md:pt-28">
      <h1 className="font-bold lg:text-6xl md:text-40 lg:w-[70%] md:w-[80%] w-full px-8 text-32 leading-10 md:leading-[52px] ">
        Unlock Borderless Commerce: Simplified Shopping, Shipping and Trade
      </h1>
      <p className="w-full px-10 text-lg lg:w-5/12 md:w-7/12 text-elr-black-300">
        Enjoy effortless international and local shopping with streamlined
        shipping and secure payment.
      </p>
    </div>
    <div className="relative flex items-center justify-center gap-6 mb-16 h-11">
      <a
        target="_blank"
        href="https://apps.apple.com/app/errandlr/id6499235011"
        rel="noreferrer"
      >
        <img src={appStore} alt="" className="w-40 cursor-pointer" />
      </a>
      <a
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.errandlr.commerce&pcampaignid=web_share"
        rel="noreferrer"
      >
        <img src={googlePlay} alt="" className="w-40 cursor-pointer" />
      </a>
    </div>
    <div className="relative flex items-center justify-center">
      <img src={appMockup} alt="app-mockup" className="md:block md:w-6/12" />
    </div>
  </div>
);
